import React from 'react';
import 'styles/app.scss';
import Home from './pages/Home';

function App() {
    return (
        <Home />
    );
}

export default App;
